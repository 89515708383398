import React, { PureComponent } from 'react';
import { string } from 'prop-types';
import { verbosePeople } from '../../BookingPath/containers/BookingPreview/modules';
import { D } from '../../common';
import { fetch, getDefaultLang } from '../../components';
import {
  Digest, FeaturedInfo, NewBooking, Footer, Status, HeaderSimple,
} from '../../containers';
import appContext, { contextPropTypes } from '../../context/appContext';
import '../../css/layouts/common.css';
import '../../css/layouts/Confirmation.css';

class Confirmation extends PureComponent {
  static propTypes = {
    hash: string.isRequired,
    context: contextPropTypes,
  };

  static defaultProps = {
    context: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    const { _getData } = this;
    _getData();
  }

  _printVoucher = () => {
    const { props: { hash } } = this;
    window.open(`${window.location.protocol}//${window.location.host}/voucher/${hash}`, '_self');
  }

  _setKey = (from) => {
    const dateNow = new Date().getTime();
    const dateBook = new Date(from).getTime();

    return dateNow > dateBook ? 'CONFIRMATION_EXPIRED' : 'CONFIRMATION';
  }

  _getData = () => {
    const { props: { context: { onShowErrorModal }, hash }, _setDataState, _setKey } = this;
    const service = `booking/${hash}/confirm`;
    fetch(service, 'POST')
      .then((response) => {
        if (response.errorStatus) {
          onShowErrorModal(response.errorStatus);
        } else {
          _setDataState(
            _setKey(
              response.dates[0].formatted,
            ),
            response,
          );
        }
      });
  };

  _setDataState = (key, data) => {
    const { props: { context }, _printVoucher } = this;
    const defaultLang = getDefaultLang(data.language);
    const { language, l10n } = defaultLang;
    context.onData('language', language);
    context.onData('l10n', l10n);

    this.setState({
      pax: verbosePeople(l10n, data.prices),
      buttonAction: _printVoucher,
      key,
      data,
    });
  }

  render() {
    const {
      state: {
        buttonAction, pax, data, key,
      },
      props: { context: { language } },
    } = this;
    return (
      <div>
        { data && (
          <div>
            <HeaderSimple />
            <div className="fullLayout confirmationLayout">
              <Status
                data={data}
                dictionaryKey={key}
              />
              {key === 'CONFIRMATION' && (
                <Digest
                  data={data}
                  buttonText={D[language][key].BUTTON}
                  buttonAction={buttonAction}
                  bookingActivity={data.experience.name[language]}
                  bookingCode={data.voucherCode}
                  bookingPax={pax}
                  bookingImage={data.experience.image}
                  bookingPrice={data.total.value}
                  bookingSymbol={data.prices[0].symbol}
                  bookingDate={data.dates[0].formatted}
                />
              )}
              {key === 'CONFIRMATION_EXPIRED' && (
                <NewBooking
                  data={data}
                />
              )}
              <FeaturedInfo
                providerName={data.provider.name}
                textPhone={data.provider.phone}
                textMail={data.provider.email}
              />
              <Footer />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default appContext(Confirmation);
