import React, { PureComponent } from 'react';
import {
  func, number, shape, string,
} from 'prop-types';
import { Text, Image } from '../../components';
import { IconText, Price, Info } from '../index';
import Colors from '../../common/colors';
import setFormatDate from '../../components/modules/setFormatDate';
import { C, D } from '../../common';
import '../../css/containers/Digest.css';
import appContext, { contextPropTypes } from '../../context/appContext';
import { Button } from '../../reactor/components';
import '../../css/layouts/common.css';


class Digest extends PureComponent {
  static propTypes = {
    data: shape({}),
    buttonText: string,
    buttonAction: func,
    bookingActivity: string,
    bookingCode: string,
    bookingDate: string,
    bookingPax: string,
    bookingImage: string,
    bookingPrice: number,
    bookingSymbol: string,
    context: contextPropTypes,
  };

  static defaultProps = {
    data: undefined,
    buttonText: undefined,
    buttonAction: undefined,
    bookingActivity: undefined,
    bookingCode: undefined,
    bookingDate: undefined,
    bookingPax: undefined,
    bookingImage: undefined,
    bookingPrice: undefined,
    bookingSymbol: undefined,
    context: undefined,
  };

  _getDialogProps = (data, language) => {
    const {
      experience: { limitHoursToCancel },
      state,
      updated,
      dates,
    } = data;
    const dialogObject = {
      text: null,
      class: null,
    };
    if (limitHoursToCancel > 0
      && state !== 'cancelledByProvider'
      && state !== 'cancelledByCustomer'
    ) {
      const today = new Date();
      const startDate = new Date(dates[0].formatted);
      startDate.setHours(
        startDate.getHours() - limitHoursToCancel,
      );
      const limitDateToCancel = setFormatDate(startDate, 'COMPLETE', language);
      if (today.getTime() < startDate.getTime()) {
        dialogObject.text = `${D[language].DIGEST.CANCELLATION} ${limitDateToCancel}`;
        dialogObject.class = C.COLORS.SECONDARY;
      } else {
        dialogObject.text = D[language].DIGEST.BOOKING_NOT_CANCELLABLE;
        dialogObject.class = C.COLORS.ERROR;
      }
    } else if (limitHoursToCancel === 0
      && state !== 'cancelledByProvider'
      && state !== 'cancelledByCustomer'
    ) {
      dialogObject.text = D[language].DIGEST.BOOKING_NOT_CANCELLABLE;
      dialogObject.class = C.COLORS.ERROR;
    } else {
      const cancellationDate = new Date(updated);
      const cancellationDateFormatted = setFormatDate(cancellationDate, 'COMPLETE', language);
      dialogObject.text = `${D[language].DIGEST.CANCELLED}${cancellationDateFormatted}`;
      dialogObject.class = C.COLORS.ERROR;
    }
    return dialogObject;
  };

  render() {
    const {
      props: {
        data,
        buttonText,
        buttonAction,
        bookingActivity,
        bookingCode,
        bookingDate,
        bookingPax,
        bookingImage,
        bookingPrice,
        bookingSymbol,
        context: { language, isCancellationSend },
      },
      _getDialogProps,
    } = this;
    const dialogProps = data && language ? _getDialogProps(data, language) : null;
    const digestCode = data && language ? D[language].DIGEST.CODE : null;
    const digestPax = data && language ? D[language].DIGEST.PAX : null;
    const bookingDateFormatted = data && language ? setFormatDate(bookingDate, 'COMPLETE', language) : null;
    return (
      <div>
        {data && language && (
          <div className="digest">
            <div className="container">
              <div className="pictureContainer">
                <div className="tabPic">
                  <Image src={C.ICONS.CIRCLE} />
                </div>
                <div
                  className="picture"
                  style={{ backgroundImage: `url(${bookingImage})` }}
                />
              </div>
              <div className="borderPic" />
              <div className="bookingContainer">
                <Text text={bookingActivity} className="title" typeText={C.FONTS.FONT_HEADLINE_6} />
                <IconText
                  className="code"
                  icon={C.ICONS.CODE}
                  text={digestCode.replace('__CODE__', bookingCode)}
                  typeText={C.FONTS.FONT_CAPTION_1}
                />
                <IconText
                  className="code dialogMargin"
                  typeText={C.FONTS.FONT_CAPTION_1}
                  icon={C.ICONS.CALENDAR}
                  text={bookingDateFormatted}
                />
                {bookingPrice > 0 && (
                  <Info
                    dialogText={dialogProps.text}
                    extraClass={dialogProps.class}
                  />
                )}
                <IconText
                  className="code"
                  icon={C.ICONS.PAX}
                  text={digestPax.replace('__PAX__', bookingPax)}
                  typeText={C.FONTS.FONT_CAPTION_1}
                />
                { data.isAgencyBooking == null || !data.isAgencyBooking
                    ? (<div className="separator" />) : null }
                { data.isAgencyBooking == null || !data.isAgencyBooking
                  ? (
                    <div className="containerPrice">
                      <Price
                        price={bookingPrice}
                        symbol={bookingSymbol}
                        language={language}
                        className="price"
                      />
                    </div>)
                  : null }
                { buttonText != null && !isCancellationSend
                  ? (
                    <div className="buttonContainer">
                      <Button
                        onPress={buttonAction}
                        title={buttonText}
                        color={Colors.secondary}
                      />
                    </div>)
                  : null }
              </div>
            </div>
          </div>)}
      </div>
    );
  }
}

export default appContext(Digest);
