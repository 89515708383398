import { ENV } from 'reactor/common';
import PKG from '../package.json';

export default {
  ADULT: 'adult',
  AMOUNT: 'amount',
  AFFILIATE: {
    TRAVEL_CLUB: 'TravelClub',
  },

  BOOKING: {
    WHEN: 'when',
    ADDONS: 'addons',
    PEOPLE: 'people',
    PAYMENT: 'payment',
  },

  DEFAULT_COUNTRY: 'España',

  EVENTS: {
    DEFAULT: {
      from: 'minube',
      category_group: 'todo',
      section: 'activities',
      library: { name: PKG.name, version: PKG.version },
    },
    // Common
    ON_BACK: 'on_back',
    ON_CLOSE: 'on_close',
    ON_ERROR: 'on_error',
    ON_ITEM: 'item_loaded',
    ON_LINK: 'on_link',
    ON_NAVIGATION: 'on_navigation',
    ON_SCREEN: 'on_screen',
    ON_SCROLL: 'on_scroll',
    // Options
    ON_AVAILABILITY: 'calendar_availability',
    ON_DATE: 'calendar_selected',
    ON_SUGGESTIONS: 'on_suggestions',
    ON_SUGGESTION: 'on_suggestion',
    ON_TIMETABLE: 'timetable_selected',
    ON_ADDONS: 'on_addons',
    ON_ADD_ADDON: 'addon_selected',
    ON_REMOVE_ADDON: 'addon_unselected',
    ON_AMOUNT_PEOPLE: 'pax_selected',
    // Payment
    ON_PAYMENT_VIEW: 'on_payment_view',
    ON_PAYMENT_FORM: 'on_payment_form',
    ON_PAYMENT_COMMENT: 'on_payment_comment',
    // Booking
    ON_BOOKING: 'booking_created',
    // customEvent
    ON_COMPONENT_CLOSED: 'BookingPathClosed',
    ON_COMPONENT_LOADED: 'BookingPathLoaded',
    ON_CONFIRMATION_SUCCESS: 'BookingPathSuccess',
  },

  FETCH_PROPS: {
    endpoint: ENV.IS_PRODUCTION ? 'api.minube.com' : 'staging.api.minube.com',
    secure: true,
  },
  FORM_TIMEOUT: 750,

  GROUP: 'group',

  HELP: {
    MAIL: 'reservas@minube.com',
    PHONE: '+34 914 855 578',
  },

  LANGUAGE: 'es-ES',
  LINK: {
    PRIVACY: 'https://minube.com/politica-privacidad',
    TERMS: 'https://minube.com/terminos-y-condiciones',
  },
  LINK_PROTOCOL: {
    HTTP: 'http',
    MAIL: 'mailto',
    TEL: 'tel',
  },

  PERCENTAGE: '%',
  PERSON: 'person',
  PRESET: 'fade',
  PROVIDER: {
    CLICKOUT: 'clickout',
    OUT_PAYMENT: 'outPayment',
  },

  STORAGE: {
    AGENCY: 'minube:agency:authorization',
    AUTHORIZATION: 'minube:ota:authorization',
    COUNTRY: 'minube:ota:country',
    DISCOUNT: 'minube:code',
    JEFFREY: 'minube:jeffrey',
    LANG: 'minube:ota:lang',
    MINUBE_USER_ID: 'mn_user_id',
  },
  SYMBOL: {
    EUR: ' €',
    USD: '$',
  },

  TEST: ['A', 'B'],

  UNDEFINED_TIME: 'none',

  VEHICLE: 'vehicle',
};
